import './concept-house';

let holdBody = () => document.body.classList.toggle( 'hold' );
let widgetToggleListener = widget => {
  let widgetElement = document.querySelector( `[widget-id="${widget}"]` );
  // add close event listener
  let closeWidget = widgetElement.querySelector( '.close-widget' );
  if ( closeWidget !== null )
    closeWidget.addEventListener( 'click', () => {
      widgetElement.classList.remove( 'open' )
      if ( !widgetElement.classList.contains( 'open' ) )
        document.body.classList.remove( 'hold' );
    } );
  if ( widgetElement === null )
    return false;
  widgetElement.classList.toggle( 'open' );
  holdBody();
  if ( !widgetElement.classList.contains( 'open' ) )
    document.body.classList.remove( 'hold' );
}
let navigationWrapper = document.querySelector( 'nav.navigation-wrapper' );
if ( navigationWrapper !== null ) {
  let navigationOnClickListener = event => {
    let target = event.target;
    // toggle menu on stack click
    if ( target.classList.contains( 'navigation-stack-wrapper' ) || target.classList.contains( 'navigation-stack' ) ) {
      navigationWrapper.classList.toggle( 'open' );
      navigationWrapper.classList.contains( 'open' ) ? document.body.classList.add( 'hold' ) : document.body.classList.remove( 'hold' );
    }
    // widget listener
    if ( target.classList.contains( 'navigation-link-title' ) )
      target = target.parentNode;
    if ( target.hasAttribute( 'widget' ) ) {
      widgetToggleListener( target.getAttribute( 'widget' ) );
      navigationWrapper.classList.remove( 'open' );
    }
  };
  navigationWrapper.addEventListener( 'click', navigationOnClickListener );
}
// products gallery
let productsGrid = document.querySelector( '.products-grid' );
if ( productsGrid !== null ) {
  let toggleCurrentSlide = slideIndex => {
  };
  let productsGridClickListener = event => {
    let target = event.target;
    if ( !target.hasAttribute( 'widget' ) )
      target = target.closest( 'a[widget]' );
    if ( target === null )
      return;
    let galleryPosition = target.getAttribute( 'gallery-position' );
    galleryPosition--;
    // retrieve products dot navigation
    const productsNavigation = document.querySelector( '.products-nav' );
    if ( productsNavigation === null )
      return;
    if ( typeof productsNavigation.childNodes[galleryPosition] !== 'undefined' )
      productsNavigation.childNodes[galleryPosition].click();
    widgetToggleListener( target.getAttribute( 'widget' ) );
  }
  productsGrid.addEventListener( 'click', productsGridClickListener );
}
